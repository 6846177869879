import Axios from "axios";
export default({
    state: {
      users: []
    },
    mutations: {
      SET_USERS(state, payload){
        state.users = payload;
      }
    },
    actions: {  
      getCheckins() {
        return Axios.get(process.env.VUE_APP_API_URL+"/checkin",
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res;
        })
      },
      getCheckin(_, payload) {
        return Axios.get(process.env.VUE_APP_API_URL+"/checkin?id="+payload,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res;
        })
      },
      delCheckin(_, payload) {
        return Axios.delete(process.env.VUE_APP_API_URL+"/checkin/"+payload,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res;
        })
      },
      getCheckinProducts(_, payload) {
        return Axios.get(process.env.VUE_APP_API_URL+"/checkin/getproducts/"+payload,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res;
        })
      },
      getAvailableProducts(_, payload){
        return Axios.get(process.env.VUE_APP_API_URL+"/checkin/getavailableproducts/"+payload,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res;
        })
      },
      getPurchaseQty(_, payload){
        return Axios.get(process.env.VUE_APP_API_URL+"/inventory/getmontlypurchaseqty/"+payload.start+"/"+payload.end,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res;
        })
      },
      delCheckinProduct(_, payload){
        return Axios.delete(process.env.VUE_APP_API_URL+"/checkin/deleteproduct/"+payload,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res;
        })
      },
      addCheckin(_, payload) {
        return Axios.post(process.env.VUE_APP_API_URL+"/checkin",payload,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res;
        })
      },
      addCheckinItem(_, payload) {
        return Axios.post(process.env.VUE_APP_API_URL+"/checkin/addproduct",payload,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res;
        })
      },
      updateCheckinItem(_, payload) {
        return Axios.put(process.env.VUE_APP_API_URL+"/checkin/updateproduct/"+payload.id,payload,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res;
        })
      },
      getCheckouts() {
        return Axios.get(process.env.VUE_APP_API_URL+"/checkout",
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res;
        })
      },
      getCheckout(_, payload) {
        return Axios.get(process.env.VUE_APP_API_URL+"/checkout?id="+payload,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res;
        })
      },
      getCheckoutProducts(_, payload) {
        return Axios.get(process.env.VUE_APP_API_URL+"/checkout/getproducts/"+payload,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res;
        })
      },
      getSalesQty(_, payload){
        return Axios.get(process.env.VUE_APP_API_URL+"/inventory/getmontlysalesqty/"+payload.start+"/"+payload.end,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res;
        })
      },
      addCheckout(_, payload) {
        return Axios.post(process.env.VUE_APP_API_URL+"/checkout",payload,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res;
        })
      },
      addCheckoutItem(_, payload) {
        return Axios.post(process.env.VUE_APP_API_URL+"/checkout/addproduct",payload,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res;
        })
      },
      updateInventory(_, payload) {
        return Axios.put(process.env.VUE_APP_API_URL+"/inventory/updateinventory/"+payload.id,payload,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res;
        })
      },
      stockMovement(_, payload){
        return Axios.get(process.env.VUE_APP_API_URL+"/inventory/stockmovement/"+payload.start+"/"+payload.end+"/"+payload.batch+"/"+payload.expiry+"/"+payload.product,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res;
        })
      }
    },
    getters: {},
  });