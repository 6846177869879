import Axios from "axios";
export default({
    state: {
      suppliers: []
    },
    mutations: {
      SET_SUPPLIERS(state, payload){
        state.suppliers = payload;
      }
    },
    actions: {  
      getSuppliers() {
        return Axios.get(process.env.VUE_APP_API_URL+"/supplier",
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res;
        })
      }
    },
    getters: {},
  });