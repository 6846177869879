export default({
    state: {
      authData: null
    },
    mutations: {
      SET_AUTH_DATA(state, payload){
        state.authData = payload;
        if(payload){
            localStorage.setItem("token", "asdasdasdda!sdf456sdf.sdfsdfsdfsdfsdfsdfsdf.qweqweasdfasrevlglhl1");
            localStorage.setItem("user", payload.username);
            localStorage.setItem("access", payload.access);
        }
        else{
            localStorage.removeItem("user");
            localStorage.removeItem("access");
            localStorage.removeItem("token");
        }
      }
    },
    actions: {
      saveAuthData({commit}, payload) {
        commit('SET_AUTH_DATA', payload);
      }
    },
    getters: {},
  });