import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
//import Axios from "axios";
import "./plugins/bootstrap-vue";
import store from "./store/Store";
import router from "./router/Router";
import "./assets/scss/style.scss";
import App from "./App.vue";
import VueFeather from "vue-feather";
import InstantSearch from "vue-instantsearch";
import Select2 from 'v-select2-component';
import VueMoment from 'vue-moment';
import html2canvas from 'html2canvas';
// i18n
import i18n from './i18n/i18n.js'
import "vue-hljs/dist/style.css";
Vue.use(VueMoment);
Vue.use(VueFeather);
Vue.use(html2canvas);

Vue.config.productionTip = false;

import vueHljs from "vue-hljs";
import hljs from "highlight.js";
//import NProgress from "nprogress";

Vue.component('Select2', Select2);


/*Axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    NProgress.start();
    return config;
}, function (error) {
    // Do something with request error
    console.log(error);
    return Promise.reject(error);
});

  // Add a response interceptor
Axios.interceptors.response.use(function (response) {
    NProgress.done();
    return response;
}, function (error) {
    // Do something with response error
    console.log(error);
    return Promise.reject(error);
});*/


//use
Vue.use(vueHljs, { hljs });
Vue.use(InstantSearch);
new Vue({ store, router,i18n, render: (h) => h(App) }).$mount("#app");
