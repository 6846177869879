import Axios from "axios";
export default({
    state: {
      salesmen: []
    },
    mutations: {
      SET_SALESMEN(state, payload){
        state.salesmen = payload;
      }
    },
    actions: {  
      getSalesmen() {
        return Axios.get(process.env.VUE_APP_API_URL+"/salesman",
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res;
        })
      }
    },
    getters: {},
  });